import "./global.css?modules=false";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../App.scss";

// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {}
export { default as logo } from "./assets/img/logo_color.svg";
